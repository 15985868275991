import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { ReactComponent as IconMail } from 'static/svg/icon_mail_24x24.svg'
import { ReactComponent as IconPhone } from 'static/svg/icon_telefone_24x24.svg'

import * as actions from 'redux/actions'

import Modal from '../Modal'
import { Avatar, Typography, Spinner } from '@telavita-core/react-design-kit'

import { PlanGroupCategoryEnum } from 'settings/_planGroupSettings'
import { apiEndpoints } from 'settings/_apiSettings'
import axios from 'settings/axios'

import { getCorporateEligibility } from 'services/corporateEligibilityService'
import { getLastValidityDetail } from 'services/lastValidityDetail'

import { cellphoneMask } from 'utils'
import { getTreatmentStatus } from 'services/treatments'

import PopoverMenu from 'components/PopoverMenu'
import EnterpriseEligibility from './components/EnterpriseEligibility/EnterpriseEligibility'
import CorporateEligibility from './components/CorporateEligibility/CorporateEligibility'
import { PatientPersonalData } from './components/PatientPersonalData/PatientPersonalData'

import { INACTIVE } from 'settings/_personSettings'
import { INELIGIBLE } from 'settings/_patientTreatmentStatus'
import { isPsychiatryEnabled } from 'utils/isPsychiatryEnabled'

const PatientDetail = ({
  patientData: {
    full_name,
    email,
    cellphone_number,
    date_created,
    username,
  },
}) => {
  const [loadingPlans, setLoadingPlans] = useState(false)
  const [tab, setTab] = useState('ACTIVE')
  const [loadingPlansEligibility, setLoadingPlansEligibility] = useState(true)
  const [plans, setPlans] = useState([])

  const [activePlansEligibility, setActivePlansEligibility] = useState([])
  const [inactivePlansEligibility, setInactivePlansEligibility] = useState([])


  const dispatch = useDispatch()

  const {
    CARE_PLUS,
    CORPORATE_LIMITED,
    CORPORATE_UNLIMITED,
    ENTERPRISE,
    VITTA,
    CAPITATION,
  } = PlanGroupCategoryEnum

  const fetchPlansEligibility = async (plans) => {
    if (plans.length <= 0) return

    setLoadingPlansEligibility(true)

    let plansEligibility = []

    const lastPlan = plans.slice(-1)[0]

    for await (const plan of plans) {
      const isCorporate = [CORPORATE_LIMITED, CORPORATE_UNLIMITED].includes(
        plan.plan_group?.category
      )

      const isCapitation = plan.plan_group?.category === CAPITATION

      const isEnterprise = ([CARE_PLUS, ENTERPRISE]).includes(plan.plan_group?.category)

      if (isCorporate) {
        await getCorporateEligibility({
          username: email,
          planCode: plan.plan_code,
        }).then((eligibility) => {
          plansEligibility = [...plansEligibility, {
            ...plan,
            eligibility,
            isCorporate: true,
            plan_group: {
              ...plan.plan_group,
            }
          },]
        })
      } else if (isEnterprise || isCapitation) {
        let psychiatryEligibility
        const showPsychiatry = isPsychiatryEnabled(plan.plan_group.code)
        if (showPsychiatry) {
          psychiatryEligibility = await getTreatmentStatus({
            userName: username,
            consultationType: 'PSIQ',
            planCode: plan.plan_code
          }).catch((error) => {
            console.error(error)
          })
        }
        await getLastValidityDetail(email, plan.plan_code).then(
          (validityDetail) => {
            plansEligibility = [...plansEligibility, {
              ...plan,
              eligibility: validityDetail,
              psychiatry: psychiatryEligibility,
              showPsychiatry: showPsychiatry
            },]
          }
        ).catch(err => {
          console.log(err)
          plansEligibility = [...plansEligibility, {
            ...plan,
            isActive: plan.status !== INACTIVE,
            eligibility: {},
            psychiatry: psychiatryEligibility,
            showPsychiatry: showPsychiatry
          },]
        })
      } else {
        plansEligibility = [...plansEligibility, plan]
      }

      if (plan.plan_code === lastPlan.plan_code) {
        setLoadingPlansEligibility(false)
      }

    }
    return plansEligibility
  }

  const getActivePlansEligibility = async () => {
    const activePlans = plans.filter(plan => plan.status !== INACTIVE)

    if (activePlans.length <= 0) return

    const data = await fetchPlansEligibility(activePlans)

    const hasMoreThenOnePlan = data.length > 2

    const plansFilteredWithoutParticular = hasMoreThenOnePlan
      ? data.filter(plan => !plan.is_particular)
      : data

    setActivePlansEligibility(plansFilteredWithoutParticular)

    setLoadingPlansEligibility(false)
  }

  const getInactivePlansEligibility = async () => {
    const inactivePlans = plans.filter(plan => plan.status === INACTIVE)

    if (inactivePlans.length <= 0) return

    const data = await fetchPlansEligibility(inactivePlans)

    setInactivePlansEligibility(data)

    setLoadingPlansEligibility(false)
  }

  useEffect(() => {
    getActivePlansEligibility()
  }, [plans])

  const getPlans = useCallback(async () => {
    try {
      setLoadingPlans(true)
      const fetchUrl = apiEndpoints.PATIENTS_PLANS(username)
      const response = await axios.get(fetchUrl)
      const plans = response.data

      setPlans(plans)

      setLoadingPlans(false)
    } catch (err) {
      setLoadingPlans(false)
    }
  }, [])

  useEffect(() => {
    getPlans()
  }, [])

  const closeModal = () => {
    dispatch(actions.hideModal())
  }

  return (
    <Modal customClassName='PatientDetailModal' btnText='Fechar' handleClick={() => closeModal()} hasButton={true}>
      <div className='ContainerPatientDetail'>
        <div className='ContainerPatientDetail__header'>
          <Avatar alt='paciente' src='/static/avatar.png' size='large' />
          <div className='ContainerPatientDetail__header__userData'>
            <span>Paciente</span>
            <Typography weight='bold' variant='header4'>
              {full_name}
            </Typography>
            <ul className='ContainerPatientDetail__header__userData__list'>
              <li>
                <IconPhone />
                <Typography variant='content2'>
                  {cellphoneMask(cellphone_number)}
                </Typography>
              </li>
              <li>
                <IconMail />
                <Typography variant='content2'>{email}</Typography>
              </li>
            </ul>
          </div>
        </div>
        
        <PatientPersonalData patientEmail={email} />
        
        { loadingPlans ||
        loadingPlansEligibility ? (
            <Spinner inlineStyles={{ margin: '0 auto' }} />
          ) : (
          <>
            {(
              <div className='ContainerPatientDetail__statusSelector'>
                <Typography weight='bold' variant='content2'>
                Convênios
                </Typography>
                <PopoverMenu
                  label='VISUALIZAR CONVÊNIOS:'
                  options={[
                    {
                      id: 'ACTIVE',
                      title: 'Ativos',
                      onClick: () => setTab('ACTIVE'),
                    },
                    {
                      id: 'INACTIVE',
                      title: 'Inativos',
                      onClick: () => {
                        setTab('INACTIVE')
                        if (inactivePlansEligibility.length <= 0) {
                          getInactivePlansEligibility()
                        }
                      },
                    },
                  ]}
                  selectedOption={tab}
                  buttonText={tab === 'ACTIVE' ? 'Ativos' : 'Inativos'}
                />
              </div>
            )}
            {tab === 'ACTIVE' && (  
              <>
                {activePlansEligibility.map((plan) => {
                  if (plan?.is_particular) {
                    return (
                      <Typography weight='bolder' variant='content1'>
                        Particular (sem convênio)
                      </Typography>
                    )
                  }
                  if (plan?.plan_group.category === VITTA) {
                    return (
                      <Typography weight='bolder' variant='content1' inlineStyles={{ marginBottom: 24 }}>
                        Convênio {plan.plan_group.name} {plan.name} 
                      </Typography>
                    )
                  }
                  if (plan?.isCorporate) {
                    return (
                      <CorporateEligibility 
                        key={plan.plan_code}
                        availableCredits={plan.eligibility.availableCredits}
                        dateCreated={plan.created_at}
                        expirationDate={plan.eligibility.expirationDate}
                        frequency={plan.eligibility.frequency}
                        maxCredits={plan.eligibility.maxCredits}
                        isEligible={plan.eligibility.isEligible}
                        isCorporateLimited={plan.plan_group.category === CORPORATE_LIMITED}
                        planName={plan.name}
                        planGroupName={plan.plan_group.name}
                        isActive
                        eligibleToSchedule={plan.eligible_to_schedule}
                        patientUsername={username}
                        planCode={plan.plan_code}
                        refetchEligibility={() => getPlans()}
                      />
                    )
                  } else {
                    return (
                      <EnterpriseEligibility
                        key={plan.plan_code}
                        availableCredits={plan.eligibility.availableCredits}
                        maxAppointment={plan.eligibility.maxAppointment}
                        cardNumber={plan.card_number}
                        dateCreated={plan.created_at}
                        endDate={plan.eligibility.endDate}
                        status={plan.eligibility.status}
                        isValidatedOrFinished={[
                          'VALIDATED',
                          'FINISHED',
                          'VALIDATED_WITHOUT_MEDICAL_REQUEST',
                        ].includes(plan.eligibility.status)}
                        isWaitingOrDisapproved={[
                          'WAITING_TECHNICAL_EVALUATION',
                          'WAITING_VALIDATION',
                          'DISAPPROVED',
                          'INVALIDATED',
                        ].includes(plan.eligibility.status)}
                        planName={plan.name}
                        planGroupName={plan.plan_group.name}
                        isActive
                        cycleDate={plan?.psychiatry?.cycleDate}
                        cycleStatus={plan?.psychiatry?.cycleStatus}
                        showPsychiatry={plan?.showPsychiatry}
                        treatmentNotStarted={Object.keys(plan.eligibility).length <= 0}
                        eligibleToSchedule={plan.eligible_to_schedule}
                        patientUsername={username}
                        planCode={plan.plan_code}
                        refetchEligibility={() => getPlans()}
                      />
                    )
                  }
                })}
              </>
            )}
            {tab === 'INACTIVE' && (
              <>
                {inactivePlansEligibility.length > 0 ? (
                  <>
                {inactivePlansEligibility.map((plan) => {
                  if (plan?.isCorporate) {
                    return (
                      <CorporateEligibility 
                        key={plan.plan_code}
                        availableCredits={plan.eligibility.availableCredits}
                        dateCreated={plan.created_at}
                        expirationDate={plan.eligibility.expirationDate}
                        frequency={plan.eligibility.frequency}
                        maxCredits={plan.eligibility.maxCredits}
                        isEligible={plan.eligibility.isEligible}
                        isCorporateLimited={plan.plan_group.category === CORPORATE_LIMITED}
                        planName={plan.name}
                        planGroupName={plan.name}
                        showPlanDetails={plan.reason !== null}
                        inactivatePlanInfo={{
                          inactivatedAt: plan.inactivated_at,
                          inactivatedBy: plan.inactivated_by,
                          reason: plan.reason,
                          moreInfo: plan.more_info
                        }}
                        eligibleToSchedule={plan.eligible_to_schedule}
                        patientUsername={username}
                        planCode={plan.plan_code}
                        refetchEligibility={() => getPlans()}
                      />
                    )
                  } else {
                    return (
                      <EnterpriseEligibility
                        key={plan.plan_code}
                        availableCredits={plan.eligibility.availableCredits}
                        maxAppointment={plan.eligibility.maxAppointment}
                        cardNumber={plan.card_number}
                        dateCreated={plan.created_at}
                        endDate={plan.eligibility.endDate}
                        status={plan.eligibility.status}
                        isValidatedOrFinished={[
                          'VALIDATED',
                          'FINISHED',
                          'VALIDATED_WITHOUT_MEDICAL_REQUEST',
                        ].includes(plan.eligibility.status)}
                        isWaitingOrDisapproved={[
                          'WAITING_TECHNICAL_EVALUATION',
                          'WAITING_VALIDATION',
                          'DISAPPROVED',
                          'INVALIDATED',
                        ].includes(plan.eligibility.status)}
                        planName={plan.name}
                        planGroupName={plan.plan_group.name}
                        cycleDate={plan?.psychiatry?.cycleDate}
                        cycleStatus={plan?.psychiatry?.cycleStatus ?? INELIGIBLE}
                        showPsychiatry={plan?.showPsychiatry}
                        hasTreatment={plan?.eligibility !== null}
                        showPlanDetails={plan.reason !== null}
                        inactivatePlanInfo={{
                          inactivatedAt: plan.inactivated_at,
                          inactivatedBy: plan.inactivated_by,
                          reason: plan.reason,
                          moreInfo: plan.more_info
                        }}
                        eligibleToSchedule={plan.eligible_to_schedule}
                        patientUsername={username}
                        planCode={plan.plan_code}
                        refetchEligibility={() => getPlans()}
                      />
                    )
                  }
                })}
                  </>
                ) : (
                  <div className='ContainerPatientDetail__InactiveContainer'>
                    <Typography variant='content3' style={{ color: '#898989' }}>
                    Nenhum convênio inativo
                    </Typography>
                  </div>
                )}
              </>
            )}
          </>
          )}
        {/* {historicPlans.map((historicPlan, index) => {
          if (index === 0) return null

          const formattedCreatedDate = format(
            new Date(historicPlan.created_at),
            'dd/MM/yyyy'
          )
          const formattedCreatedTime = format(
            new Date(historicPlan.created_at),
            "HH'h'mm"
          )

          return (
            <PlanDetail
              key={historicPlan.id}
              date={formattedCreatedDate}
              time={formattedCreatedTime}
              toggleDetail={() => handleToggleDetail(historicPlan.id)}
              displayDetail={historicPlan.details}
              lastPlanName={`${historicPlan.plan_group.lastPlanGroupName} - ${historicPlan.lastPlanName}`}
              lastCardNumber={historicPlan.lastCardNumber}
              reason={historicPlan.reason}
              moreInfo={historicPlan.more_info}
              fullName={historicPlan.created_by.full_name}
            />
          )
        })} */}
      </div>
    </Modal>
  )
}

PatientDetail.propTypes = {
  handleClick: PropTypes.func,
  closeModal: PropTypes.func,
  full_name: PropTypes.string,
  email: PropTypes.string,
  cellphone_number: PropTypes.string,
  date_created: PropTypes.string,
  patientData: PropTypes.shape({
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        plan: PropTypes.shape({
          name: PropTypes.string,
          code: PropTypes.string,
          group: PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.string.isRequired,
            frequency: PropTypes.string,
          }).isRequired,
        }).isRequired,
      })
    ),
  }),
}

export default PatientDetail
