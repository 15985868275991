import React, { useState } from 'react'

import { Typography } from '@telavita-core/react-design-kit'

import { toDayMonthYear } from 'utils/dateTimeHandlers'

import { ReactComponent as CheckIcon } from 'static/svg/icon-check-green.svg'

import CoreIcons from 'components/CoreIcons'
import PatientTreatmentsEligibility from 'components/PatientTreatmentsEligibility'
import { Specialities } from 'settings/_specialitiesSettings'
import { MakePatientEligibleModal } from 'containers/ModalManager/Modals/MakePatientEligibleModal'
import {
  ALLOWED_TO_SCHEDULE,
  CONSULTATION_SCHEDULED,
  INELIGIBLE,
  MEDICAL_RETURN_AVAILABLE,
  MEDICAL_RETURN_SCHEDULED,
  TREATMENT_NOT_STARTED,
  SCHEDULE_CYCLE_END,
} from 'settings/_patientTreatmentStatus'
import { format } from 'date-fns'
import { PlanDetail } from 'components/PlanDetail/PlanDetail'
import ToggleFloatButton from 'components/ToggleFloatButton'

import * as S from '../styles'

const ValidityStatusName = {
  WAITING_VALIDATION: 'Aguardando validação',
  WAITING_TECHNICAL_EVALUATION: 'Aguardando avaliação técnica',
  VALIDATED: 'Validado',
  VALIDATED_WITHOUT_MEDICAL_REQUEST: 'Validado sem pedido médico',
  FINISHED: 'Finalizado',
  DISAPPROVED: 'Reprovado',
  INVALIDATED: 'Invalidado',
}

interface EnterpriseEligibilityProps {
  availableCredits: string | number
  cardNumber: string
  isValidatedOrFinished: boolean
  maxAppointment: string | number
  isWaitingOrDisapproved: boolean
  error: boolean
  endDate: string
  dateCreated: string
  status: string
  planName: string
  planGroupName: string
  isActive: boolean
  cycleStatus: string
  cycleDate: string
  showPsychiatry: boolean
  treatmentNotStarted: boolean
  inactivatePlanInfo?: {
    inactivatedAt: string
    inactivatedBy: string
    moreInfo: string
    reason: string
  }
  showPlanDetails?: boolean
  eligibleToSchedule: boolean | null
  patientUsername: string
  planCode: string
  refetchEligibility: () => void
}

const EnterpriseEligibility = ({
  availableCredits,
  cardNumber,
  dateCreated,
  endDate,
  error,
  isValidatedOrFinished,
  isWaitingOrDisapproved,
  maxAppointment,
  status,
  planName,
  planGroupName,
  isActive,
  cycleStatus,
  cycleDate,
  showPsychiatry,
  treatmentNotStarted,
  inactivatePlanInfo,
  showPlanDetails = false,
  eligibleToSchedule,
  patientUsername,
  planCode,
  refetchEligibility,
}: EnterpriseEligibilityProps): JSX.Element => {
  const [openDetail, setOpenDetail] = useState(false)
  const [showMakePatientEligibleModal, setShowMakePatientEligibleModal] = useState(false)

  const setConditionalClass = () => {
    if (
      endDate &&
      new Date() > new Date(endDate) &&
      status === 'FINISHED'
    ) {
      return {
        message:
          '*Com a validade do(s) crédito(s) expirada, o paciente pode solicitar a renovação, acessando o botão que aparece no painel do convênio dele.',
        className: `enterprise --${status
          ?.replaceAll('_', '-')
          .toLowerCase()} dateExpiration`,
      }
    } else if (
      endDate &&
      status === 'WAITING_VALIDATION'
    ) {
      return {
        message: (
          <>
            <CheckIcon /> Paciente solicitou novos créditos em{' '}
            <strong>{toDayMonthYear(dateCreated)}</strong>
          </>
        ),
        className: `enterprise --${status
          ?.replaceAll('_', '-')
          .toLowerCase()}`,
      }
    } else if (
      availableCredits === 0 &&
      new Date() < new Date(endDate)
    ) {
      return {
        message: (
          <>
            *Assim que o paciente realizar todas as consultas agendadas,
            pediremos a renovação dos créditos junto ao convênio e{' '}
            <strong>em até 3 dias úteis</strong> avisaremos por e-mail e SMS.
          </>
        ),
        className: `enterprise --${status
          ?.replaceAll('_', '-')
          .toLowerCase()}`,
      }
    } else if (error) {
      return {
        message: '',
        className: 'enterprise --not-validity',
      }
    }
    return {
      message: '',
      className: `enterprise --${status
        ?.replaceAll('_', '-')
        .toLowerCase()}`,
    }
  }

  const renderMessage = () => {
    const result = setConditionalClass()

    if (!result.message) return

    return (
      <div
        className={`ContainerPatientDetail__planGroup__info ${result.className}`}
      >
        <Typography variant='content3' color='danger'>
          {result.message}
        </Typography>
      </div>
    )
  }

  const renderEligibilityInfo = () => {
    if (treatmentNotStarted) {
      return (
        <div className='ContainerPatientDetail__PsiqTabWrapper'>
          <Typography variant='content2'>
            O paciente ainda não iniciou um tratamento com um <b>psicólogo</b>
            .
          </Typography>
          <Typography variant='content2'>
            Para iniciar o tratamento, ele deve clicar no botão{' '}
            <i>“Solicitar consulta com psicólogo</i> em seu painel de
            convênio.
          </Typography>
        </div>
      )
    } else {
      return (
        <>
          <ul className='ContainerPatientDetail__planGroup__list'>
            <li>
              <Typography variant='content2'>Carteirinha:</Typography>
              <Typography variant='content2'>
                {cardNumber}
              </Typography>
            </li>
            <li>
              <Typography variant='content2'>
                Consultas disponíveis:
              </Typography>
              <Typography
                variant='content2'
                weight='bolder'
                customClassName={
                  availableCredits === 0 ? 'danger' : ''
                }
              >
                {isValidatedOrFinished
                  ? `${availableCredits} de ${maxAppointment}`
                  : '-'}
              </Typography>
            </li>
            <li>
              <Typography variant='content2'>Válidas até:</Typography>
              <Typography variant='content2'>
                {isWaitingOrDisapproved || error
                  ? '-'
                  : toDayMonthYear(endDate)}
              </Typography>
            </li>
            <li>
              <Typography variant='content2'>Criado em: </Typography>
              <Typography variant='content2'>
                {toDayMonthYear(dateCreated)}
              </Typography>
            </li>
            <li>
              <Typography variant='content2'>
                Status da solicitação:{' '}
              </Typography>
              <Typography variant='content2'>
                {error ? '-' : ValidityStatusName[status]}
              </Typography>
            </li>
          </ul>
          {renderMessage()}
        </>
      )
    }
  }

  const renderPsiqContent = () => {

    const formatedCycleDate =
      cycleDate && format(new Date(cycleDate), 'dd/MM/yyyy')

    if (cycleStatus === TREATMENT_NOT_STARTED) {
      return (
        <>
          <Typography variant='content2'>
            O paciente ainda não iniciou um tratamento com um <b>psiquiatra</b>.
          </Typography>
          <Typography variant='content2'>
            Para iniciar o tratamento, ele deve clicar no botão{' '}
            <i>“Solicitar consulta com psiquiatra”</i> em seu painel de
            convênio.
          </Typography>
        </>
      )
    }

    if (cycleStatus === ALLOWED_TO_SCHEDULE) {
      return (
        <Typography variant='content2'>
          O agendamento com psiquiatra está liberado.
          <br /> O paciente pode agendar{' '}
          <b className='ContainerPatientDetail__Highlight__Green'>
            1 consulta por vez
          </b>
          .
        </Typography>
      )
    }

    if (cycleStatus === CONSULTATION_SCHEDULED) {
      return (
        <>
          <Typography variant='content2'>
            O paciente já possui uma consulta agendada com psiquiatra.
          </Typography>
          <Typography variant='content3' color='danger'>
            Só é possível agendar uma nova depois que o paciente realizá-la.
          </Typography>
        </>
      )
    }

    if (cycleStatus === MEDICAL_RETURN_AVAILABLE) {
      return (
        <>
          <Typography variant='content2'>
            O paciente tem direito a um{' '}
            <b className='ContainerPatientDetail__Highlight__Green'>
              retorno médico
            </b>{' '}
            com o seu psiquiatra.
          </Typography>
          <Typography variant='content3' color='success'>
            Agende com o mesmo profissional até {formatedCycleDate}.
          </Typography>
        </>
      )
    }

    if (cycleStatus === MEDICAL_RETURN_SCHEDULED) {
      return (
        <>
          <Typography variant='content2'>
            O paciente já possui um retorno médico agendado com psiquiatra.
          </Typography>
          <Typography variant='content3' color='danger'>
            Só é possível agendar uma nova consulta a partir de{' '}
            {formatedCycleDate}.
          </Typography>
        </>
      )
    }

    if (cycleStatus === SCHEDULE_CYCLE_END) {
      return (
        <Typography variant='content2'>
          O paciente pode agendar uma nova consulta com psiquiatra{' '}
          <b className='ContainerPatientDetail__Highlight__Green'>
            a partir de {formatedCycleDate}
          </b>
          .
        </Typography>
      )
    }

    if (cycleStatus === INELIGIBLE) {
      return (
        <Typography
          variant='content3'
          color='danger'
          inlineStyles={{ lineHeight: '16px' }}
        >
          O paciente não consta mais como beneficiário do convênio para esta
          especialidade. Infelizmente, isso impossibilita o agendamento de
          consultas de psiquiatria. Verifique se a carteirinha do convênio está
          certa/atualizada.
        </Typography>
      )
    }
  }

  return (
    <div
      className={`ContainerPatientDetail__planGroup planGroupInfo__${
        setConditionalClass().className
      }`}
    >
      <div className='ContainerPatientDetail__planInfo'>
        
        <S.HeaderContainer>
          <Typography weight='bolder' variant='content1'>
            Convênio {planGroupName} {planName}
          </Typography>

          {eligibleToSchedule === false && (
            <ToggleFloatButton 
              fill='#4B4B4B'
              iconName='MoreOptions'
              menuList={[
                { 
                  code: 1, 
                  name: 'Tornar elegível', 
                  func: () => setShowMakePatientEligibleModal(true) 
                }
              ]}
            />
          )}
        </S.HeaderContainer>
        

        <S.Status active={isActive}>
          <CoreIcons
            height='13px'
            name='StatusIcon'
            width='13px'
          />
          <Typography variant='content2'>
            Convênio {isActive ? 'ativo' : 'inativo'}
          </Typography>
        </S.Status>

        {eligibleToSchedule === null
          ? null 
          : (
            <S.Status active={Boolean(eligibleToSchedule)}>
              <CoreIcons
                height='13px'
                name='StatusIcon'
                width='13px'
              />
              <Typography variant='content2'>
                {eligibleToSchedule ? 'Elegível' : 'Inelegível'}
              </Typography>
            </S.Status>
          )}

      </div>
      {showPsychiatry ? (
        <div className='ContainerPatientDetail__PatientTreatmentsEligibilityWrapper'>
          <PatientTreatmentsEligibility
            tabs={Specialities.map((specialty) => specialty.name)}
            tabsPanel={[
              <div className='ContainerPatientDetail__PsicTabWrapper'>
                {renderEligibilityInfo()}
              </div>,
              <div className='ContainerPatientDetail__PsiqTabWrapper'>
                {renderPsiqContent()}
              </div>,
            ]}
          />
        </div>
      ) : (
        <>
        {renderEligibilityInfo()}
        </>
      )}
      {showPlanDetails && (
        <PlanDetail
          reasonTitle='Motivo da inativação'
          headerResume={
            <>
              Convênio inativado em<br/>
              {format(new Date(inactivatePlanInfo.inactivatedAt), 'dd/MM/yyyy')}, às {format(new Date(inactivatePlanInfo.inactivatedAt), 'HH\'h\'mm')}<br/>
              Por {inactivatePlanInfo.inactivatedBy}
            </>
          }
          toggleDetail={() => setOpenDetail(prevState => !prevState)}
          displayDetail={openDetail}
          reason={inactivatePlanInfo.reason}
          moreInfo={inactivatePlanInfo.moreInfo}
        />
      )}

      {showMakePatientEligibleModal && (
        <MakePatientEligibleModal
          username={patientUsername}
          planCode={planCode}
          handleClose={() => setShowMakePatientEligibleModal(false)}
          onSuccess={() => refetchEligibility()}
        />
      )}
    </div>
  )
}

export default EnterpriseEligibility